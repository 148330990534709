<template>
  <v-app>
    <!-- <v-main> -->
    <v-dialog
    v-model="dialog"
    max-width="450"
    >
      <v-card>
       <v-card-title
        class="font-weight-bold"
        >
          <h2>Response</h2>
        </v-card-title>
        <v-card-text>
          <div>
            <h4
            v-show="signUpSuccess"
            >You have successfully created a new task!</h4>
            <h4>{{ dialogMssg }}</h4>
            <h4
            v-show="signUpSuccess"
            >Please use this Task ID to sign in.</h4>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
          color="green darken-1"
          text
          @click="dialog = false"
          >
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
      <v-container
      class="fill-height pa-16"
      fluid
      >
        <v-row
        align="center"
        justify="center"
        >
          <v-col
          cols="12"
          sm="8"
          md="8"
          >
            <v-card
            class="elevation-12"
            >
            <v-window
            v-model="step"
            >
              <v-window-item
              :value="1"
              >
              <v-row>
                <v-col
                cols="12"
                md="8"
                >
                  <v-card-text
                  class="mt-12"
                  >
                    <h1
                    class="text-center text-h1 font-weight-bold"
                    style="color: #333333;"
                    >
                      Sign in to PRASEMap
                    </h1>
                    <v-form
                    v-model="signInValid"
                    ref="signInForm"
                    >
                      <v-text-field
                      v-model="signInEmail"
                      label="Email"
                      prepend-icon="mdi-email"
                      :rules="emailRule"
                      required
                      ></v-text-field>
                      <v-text-field
                      v-model="taskId"
                      label="Task ID"
                      prepend-icon="mdi-account-box"
                      :rules="taskIdRule"
                      required
                      >
                      </v-text-field>
                      <div
                      class="text-center mt-3"
                      >
                      <v-btn
                      color="#333333"
                      rounded
                      dark
                      class="mb-3"
                      @click="signIn"
                      >
                        SIGN IN
                      </v-btn>
                      </div>
                    </v-form>
                  </v-card-text>
                </v-col>
                <v-col
                cols="12"
                md="4"
                style="background: #003366;"
                >
                  <v-card-text
                  class="mt-14 pt-14 white--text"
                  >
                    <h1
                    class="text-center"
                    style="line-height: 40px;"
                    >
                      Hi, Friends!
                    </h1>
                    <h5
                    class="text-center"
                    >
                      Create a Task and Start to Perform Knowledge Graph Alignment!
                    </h5>
                  </v-card-text>
                  <div
                  class="text-center"
                  >
                    <v-btn
                    rounded
                    outlined
                    dark
                    @click="step++"
                    class="mb-14"
                    >
                      SIGN UP
                    </v-btn>
                  </div>
                </v-col>
              </v-row>
              </v-window-item>
              <v-window-item
              :value="2"
              >
                <v-row
                class="fill-height"
                >
                  <v-col
                  cols="12"
                  md="4"
                  style="background: #003366;"
                  >
                    <v-card-text
                    class="white--text mt-14 pt-14"
                    >
                      <h1
                      class="text-center"
                      style="line-height: 40px;"
                      >
                        Welcome Back
                      </h1>
                      <h5
                      class="text-center"
                      >
                        Use Your Task ID to Trace Your Knowledge Graph Alignment Task!
                      </h5>
                    </v-card-text>
                    <div class="text-center">
                      <v-btn
                      rounded
                      outlined
                      dark
                      @click="step--"
                      class="mb-14"
                      >
                      SIGN IN
                      </v-btn>
                    </div>
                  </v-col>
                  <v-col
                  cols="12"
                  md="8"
                  >
                    <v-card-text
                    class="mt-12"
                    >
                      <h1
                      class="text-center text-h1 font-weight-bold"
                      style="color: #333333;"
                      >Create Task
                      </h1>
                      <v-form
                      v-model="signUpValid"
                      ref="signUpForm"
                      >
                        <v-text-field
                        v-model="userName"
                        label="Name"
                        prepend-icon="mdi-account-circle"
                        :rules="userNameRule"
                        required
                        >
                        </v-text-field>
                        <v-text-field
                        v-model="signUpEmail"
                        label="Email"
                        prepend-icon="mdi-email"
                        :rules="emailRule"
                        required
                        ></v-text-field>
                        <div
                        class="text-center mt-3"
                        >
                        <v-btn
                        rounded
                        color="#333333"
                        dark
                        class="mb-3"
                        @click="signUp"
                        >
                          SIGN UP
                        </v-btn>
                        </div>
                      </v-form>
                    </v-card-text>
                  </v-col>
                </v-row>
              </v-window-item>
            </v-window>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    <!-- </v-main> -->
  </v-app>
</template>

<script>
  import api from '@/utils/api.js'
  export default {
    data () {
      return {
        step: 1,
        dialog: false,
        signInValid: false,
        signUpValid: false,
        signInEmail: null,
        taskId: null,
        userName: null,
        signUpEmail: null,
        signUpSuccess: false,
        generatedTaskId: null,
        dialogMssg: '',
        emailRule: [
          (input) => {
            if (input === null || input.length === 0) {
              return 'Email is required'
            }
            const reg = /^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$/
            if (!reg.test(input)) {
              return 'E-mail must be valid'
            }
            return true
          },
        ],
        taskIdRule: [
          (input) => {
            if (input === null || input.length === 0) {
              return 'Task ID is required'
            }
            return true
          },
        ],
        userNameRule: [
          (input) => {
            if (input === null || input.length === 0) {
              return 'User Name is required'
            }
            return true
          },
        ],
      }
    },
    mounted () {
      this.taskId = 'prasemapdemo'
      this.signInEmail = 'demo@prasemap.com'
    },
    methods: {
      signUp () {
        this.$refs.signUpForm.validate()
        if (this.signUpValid) {
          this.signUpHandler()
        }
      },
      signIn () {
        this.$refs.signInForm.validate()
        if (this.signInValid) {
          this.signInHandler()
        }
      },
      signInHandler () {
        api.signIn(this.taskId, this.signInEmail).then((res) => {
          this.signUpSuccess = false
          if (res.status === 200) {
            if (res.data.success) {
              localStorage.setItem('taskId', this.taskId)
              localStorage.setItem('userEmail', this.signInEmail)
              this.$router.push('/page/home')
            } else {
              this.dialogMssg = 'ERROR: Task ID and email do not match.'
              this.dialog = true
            }
          } else {
            this.dialogMssg = 'ERROR: Fail to get response from server.'
            this.dialog = true
          }
        })
      },
      signUpHandler () {
        api.signUp(this.userName, this.signUpEmail).then((res) => {
          this.signUpSuccess = false
          if (res.status === 200) {
            if (res.data.success) {
              this.generatedTaskId = res.data.data
              this.dialogMssg = 'Task ID: ' + this.generatedTaskId
              this.signUpSuccess = true
              this.$refs.signUpForm.reset()
            } else {
              this.dialogMssg = 'ERROR: ' + res.data.message
            }
          } else {
            this.dialogMssg = 'ERROR: Fail to get response from server.'
          }
          this.dialog = true
        })
      },
    },
  }
</script>

<style>
</style>
